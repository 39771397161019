exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ch-a-2-a-tsx": () => import("./../../../src/pages/ch-a2a.tsx" /* webpackChunkName: "component---src-pages-ch-a-2-a-tsx" */),
  "component---src-pages-ch-finance-tsx": () => import("./../../../src/pages/ch-finance.tsx" /* webpackChunkName: "component---src-pages-ch-finance-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-dev-tsx": () => import("./../../../src/pages/dev.tsx" /* webpackChunkName: "component---src-pages-dev-tsx" */),
  "component---src-pages-devops-tsx": () => import("./../../../src/pages/devops.tsx" /* webpackChunkName: "component---src-pages-devops-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-tsx": () => import("./../../../src/pages/it.tsx" /* webpackChunkName: "component---src-pages-it-tsx" */),
  "component---src-pages-plastic-blog-tsx": () => import("./../../../src/pages/plastic-blog.tsx" /* webpackChunkName: "component---src-pages-plastic-blog-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KZ49XNH","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"invalidateConsentWithoutLog":true,"whitelabel":false,"lang":"it","siteId":2140395,"enableTcf":true,"googleAdditionalConsentMode":true,"perPurposeConsent":true,"countryDetection":true,"reloadOnConsent":true,"consentOnContinuedBrowsing":false,"cookiePolicyId":39068350,"banner":{"brandBackgroundColor":"none","brandTextColor":"black","acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#ce0000","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#ce0000","rejectButtonCaptionColor":"white","position":"float-top-center","textColor":"black","backgroundColor":"white","rejectButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"closeButtonRejects":true}},"googleTagManagerOptions":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
